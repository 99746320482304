import { MouseEvent, ReactNode, useCallback, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import ArrowUpDD from "@img/arrow_light_up.svg";

import { sidebarWidth } from "./Navigation/NavbarStyles";
import { sidePanelZIndex } from "./zIndexValues";

const transitionName = "sideOut";

const Animation = styled.div`
  z-index: ${sidePanelZIndex};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.${transitionName}-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 300ms ease-out;
  }
  &.${transitionName}-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.${transitionName}-exit-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: opacity 200ms, transform 300ms ease-in;
  }
`;

const panelFullWidth = `calc(100vw - ${sidebarWidth})`;
const panelPartialWidth = "75vw";
export const panelPadding = 40;

export function getPanelInnerWidth(expanded: boolean) {
  const width = expanded ? panelFullWidth : panelPartialWidth;
  const sidesPadding = `${panelPadding * 2}px`;
  return `calc(${width} - ${sidesPadding})`;
}

const CloseWrapper = styled.div`
  background-color: rgb(0, 0, 0, 0);
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.div<{ $expanded: boolean }>`
  display: flex;
  position: relative;
  height: 100vh;
  width: ${({ $expanded }) => ($expanded ? panelFullWidth : panelPartialWidth)};
  padding: ${panelPadding}px;
  box-sizing: border-box;
  background-color: white;
  border-left: 1px solid black;
  overflow: auto;
`;

const Cross = styled.span`
  position: absolute;
  right: 35px;
  top: 30px;
  cursor: pointer;
  font-size: 20px;
`;

const SizeArrow = styled(ArrowUpDD)<{ $expanded: boolean }>`
  position: absolute;
  left: 35px;
  top: 30px;
  cursor: pointer;
  transform: ${({ $expanded }) => ($expanded ? "rotate(90deg)" : "rotate(-90deg)")};
  height: 20px;
  width: 20px;
`;

interface SidePanelProps {
  show: boolean;
  close: () => void;
  children: ReactNode;
  toggleExpand?: () => void;
  expanded?: boolean;
}

export function SidePanel({ show, close, children, toggleExpand, expanded }: SidePanelProps) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "initial";
    };
  }, []);

  const preventClosePanel = useCallback((e: MouseEvent<HTMLElement>) => e.stopPropagation(), []);

  return (
    <CSSTransition in={show} timeout={200} appear={true} unmountOnExit classNames={transitionName}>
      <Animation>
        <CloseWrapper onClick={close}>
          <Wrapper onClick={preventClosePanel} $expanded={!!expanded}>
            <Cross title="close" onClick={close}>
              ✕
            </Cross>
            {toggleExpand && (
              <SizeArrow title="expand" onClick={toggleExpand} $expanded={expanded} />
            )}
            {children}
          </Wrapper>
        </CloseWrapper>
      </Animation>
    </CSSTransition>
  );
}
