export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601 formatted Date and Time */
  Time: { input: any; output: any };
};

export enum AdminStatus {
  Locked = "LOCKED",
  Override = "OVERRIDE",
  Unknown = "UNKNOWN",
  Unlocked = "UNLOCKED"
}

export type ApiTokens = {
  __typename?: "ApiTokens";
  googleMaps?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mapbox?: Maybe<Scalars["String"]["output"]>;
};

export type AuditLogEntity = {
  id: Scalars["ID"]["output"];
};

export enum AuditLogEntityType {
  Blockchart = "BLOCKCHART",
  Chain = "CHAIN",
  Cloudresource = "CLOUDRESOURCE",
  Device = "DEVICE",
  Federationguest = "FEDERATIONGUEST",
  Federationhost = "FEDERATIONHOST",
  Org = "ORG",
  Provision = "PROVISION",
  Site = "SITE",
  Unknown = "UNKNOWN",
  User = "USER"
}

export type AuditLogEntry = {
  __typename?: "AuditLogEntry";
  entity?: Maybe<
    | BlockChart
    | Device
    | FederationGuest
    | FederationHost
    | GenericAuditLogEntity
    | Org
    | Provision
    | ServiceChain
    | Site
    | User
  >;
  entityType?: Maybe<AuditLogEntityType>;
  message?: Maybe<Scalars["String"]["output"]>;
  org?: Maybe<Org>;
  reason?: Maybe<Scalars["String"]["output"]>;
  time?: Maybe<Scalars["Time"]["output"]>;
  type?: Maybe<AuditLogEventType>;
  user?: Maybe<User>;
};

export type AuditLogEntryEdge = {
  __typename?: "AuditLogEntryEdge";
  cursor: Scalars["String"]["output"];
  node?: Maybe<AuditLogEntry>;
};

export enum AuditLogEventType {
  Normal = "NORMAL",
  Unknown = "UNKNOWN",
  Warning = "WARNING"
}

export enum AuditLogKind {
  Block = "BLOCK",
  Chain = "CHAIN",
  Device = "DEVICE"
}

export type AuditLogs = {
  __typename?: "AuditLogs";
  edges: Array<Maybe<AuditLogEntryEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type BBox = {
  /** Northwesternmost point of a bounding box */
  nw: LatLngInput;
  /** Southeasternmost point of a bounding box */
  se: LatLngInput;
};

export type Block = {
  __typename?: "Block";
  chainRevision: Scalars["Int"]["output"];
  chart?: Maybe<BlockChart>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  controls?: Maybe<Array<Maybe<BlockControl>>>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  logEndpoint?: Maybe<LogEndpoint>;
  logEndpoints?: Maybe<Array<Maybe<LogEndpoint>>>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  selectedVersion?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Status>;
  values?: Maybe<Scalars["String"]["output"]>;
  valuesJson?: Maybe<Scalars["String"]["output"]>;
};

export type BlockLogEndpointArgs = {
  hash: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type BlockChart = AuditLogEntity & {
  __typename?: "BlockChart";
  availableVersions?: Maybe<Array<Scalars["String"]["output"]>>;
  /** keywords field in Chart.yaml */
  categories?: Maybe<Array<Scalars["String"]["output"]>>;
  /** full contents of Chart.yaml */
  chartYaml?: Maybe<Scalars["String"]["output"]>;
  /** description field in Chart.yaml */
  description?: Maybe<Scalars["String"]["output"]>;
  /** annotations.displayName in Chart.yaml */
  displayName?: Maybe<Scalars["String"]["output"]>;
  /** Chart digest, used for client caching. Cannot fetch by ID! */
  id: Scalars["ID"]["output"];
  /** icon field in Chart.yaml */
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** nearbyone/overrides.yaml, or values.yaml if it doesn't exist, converted from YAML to JSON */
  overridesJson?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/overrides.schema.json, or null if this file doesn't exist */
  overridesSchema?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/overrides.yaml, or values.yaml if it doesn't exist */
  overridesYaml?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/ui-edit.schema.json, nearbyone/ui.schema.json, or null */
  uiEditSchema?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/ui.schema.json, or null if this file doesn't exist */
  uiSchema?: Maybe<Scalars["String"]["output"]>;
  /** values.json.schema parsed as json string. Introspected from overrides if file does not exist. Includes values as defaults */
  valuesSchema?: Maybe<Scalars["String"]["output"]>;
  /** annotations.vendor field in Chart.yaml */
  vendor?: Maybe<Scalars["String"]["output"]>;
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export enum BlockChartType {
  Mec = "MEC",
  Service = "SERVICE"
}

export type BlockConnectionInput = {
  /** Connection ID */
  connId: Scalars["ID"]["input"];
  /** Field name */
  name: Scalars["String"]["input"];
};

export type BlockControl = {
  __typename?: "BlockControl";
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  options?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type BlockInput = {
  /** Chart name in Helm repo */
  blockChartName: Scalars["String"]["input"];
  /** Chart semantic version number */
  blockChartVersion: Scalars["String"]["input"];
  /** Display name of the block */
  displayName: Scalars["String"]["input"];
  /**
   * ID of a previously-deployed block to update.
   * Providing this parameter implies an update to an existing block,
   * whereas inputs with no ID will be treated as new blocks.
   */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Override values in YAML format */
  values: Scalars["String"]["input"];
};

export type ChartConfigField = {
  __typename?: "ChartConfigField";
  label: Scalars["String"]["output"];
  redacted?: Maybe<Scalars["Boolean"]["output"]>;
  required: Scalars["Boolean"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ChartKey = {
  __typename?: "ChartKey";
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export type ChartKeyInput = {
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["input"];
  /** version field in Chart.yaml */
  version: Scalars["String"]["input"];
};

export type ChartMetaInput = {
  categories: Array<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  iconUrl: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  vendor: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
};

export type CloudResourceChart = {
  __typename?: "CloudResourceChart";
  /** These are the last deployed values */
  latestConfig?: Maybe<Array<ValuesField>>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** This comes from the values yaml that come from the original chart */
  originalConfig?: Maybe<Array<ValuesField>>;
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export type CloudResourceChartListing = {
  __typename?: "CloudResourceChartListing";
  displayName: Scalars["String"]["output"];
  key: ChartKey;
};

export type CloudResourceConfigFieldInput = {
  label: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type CloudResourceInput = {
  /** Chart name in Helm repo */
  chartName: Scalars["String"]["input"];
  /** Chart semantic version number */
  chartVersion: Scalars["String"]["input"];
  /** Array of key value pairs for the Device configuration */
  config: Array<CloudResourceConfigFieldInput>;
  /** Site selector for controller */
  controllerSite: Scalars["ID"]["input"];
  /** Site that device belongs to */
  deviceSite: Scalars["ID"]["input"];
  /** Display name of device to be shown in the UI */
  displayName: Scalars["String"]["input"];
  /** Geographical position of device */
  position: LatLngInput;
};

export type Cluster = {
  __typename?: "Cluster";
  base?: Maybe<Scalars["String"]["output"]>;
  labels?: Maybe<Array<Label>>;
  specs?: Maybe<Array<Specs>>;
  syncSchedule?: Maybe<SyncSchedule>;
  version?: Maybe<Scalars["String"]["output"]>;
  workflows?: Maybe<Array<Workflow>>;
  workloads?: Maybe<Workload>;
};

export type ComputeResource = {
  __typename?: "ComputeResource";
  chart?: Maybe<ChartKey>;
  cluster?: Maybe<Cluster>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  nodes?: Maybe<Array<Node>>;
  position?: Maybe<LatLng>;
  site?: Maybe<Site>;
  status?: Maybe<Status>;
};

export type ComputeResourceInput = {
  chartName: Scalars["String"]["input"];
  chartVersion: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  position: LatLngInput;
  site: Scalars["String"]["input"];
  values: Scalars["String"]["input"];
};

export type ConfigFieldInput = {
  label: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type Connection = {
  __typename?: "Connection";
  category: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kind: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
};

export type ConnectionInput = {
  category: Scalars["String"]["input"];
  kind: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
};

export type CreateIpPoolInput = {
  cidr: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName: Scalars["String"]["input"];
  type: IpPoolTypeInput;
};

export type CreateMecSiteInput = {
  chart: ChartKeyInput;
  cluster: Scalars["ID"]["input"];
  /** Array of key value pairs passed into mecsite block as value overrides */
  config?: InputMaybe<Array<ConfigFieldInput>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName: Scalars["String"]["input"];
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreateSubnetInput = {
  cidr: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName: Scalars["String"]["input"];
  pool: Scalars["ID"]["input"];
  type: SubnetTypeInput;
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  error?: Maybe<Error>;
  user?: Maybe<User>;
};

export type DeployTargetInput = {
  host: Scalars["ID"]["input"];
  master: Scalars["ID"]["input"];
};

export type Device = AuditLogEntity & {
  __typename?: "Device";
  cloudResourceChart?: Maybe<CloudResourceChart>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  deviceType?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  metadata?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<LatLng>;
  progress?: Maybe<Progress>;
  site?: Maybe<Scalars["String"]["output"]>;
  specs?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<DeviceStatus>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  telemetryUrl?: Maybe<Array<TelemetryUrl>>;
};

/** Properties of a new device to be registered */
export type DeviceInput = {
  /** List of connections (e.g. video streams, APIs) available on the device */
  connections?: InputMaybe<Array<ConnectionInput>>;
  /** Display name of device to be shown in the UI */
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  /** Configuration file used to connect to Kubenetes Control Plane of a Cloudlet */
  kubeconfig?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional namespace for a Cloudlet */
  managedNamespace?: InputMaybe<Scalars["String"]["input"]>;
  /** Geographical position of device */
  position?: InputMaybe<LatLngInput>;
  /** Site that device belongs to */
  site: Scalars["String"]["input"];
  /** List of tags that describe the device */
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum DeviceStatus {
  Deploying = "DEPLOYING",
  Error = "ERROR",
  Ready = "READY",
  Unknown = "UNKNOWN"
}

export type DevicesResult = {
  __typename?: "DevicesResult";
  devices?: Maybe<Array<Device>>;
  hits?: Maybe<Scalars["Int"]["output"]>;
};

export type EditConnectionInput = {
  category: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  kind: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
};

export type EditDeviceInput = {
  connections?: InputMaybe<Array<EditConnectionInput>>;
  deletedConnections?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Display name of device to be shown in the UI */
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  /** Geographical position of device */
  position?: InputMaybe<LatLngInput>;
  /** Site that device belongs to */
  site?: InputMaybe<Scalars["String"]["input"]>;
  /** List of tags that describe the device */
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type EditFederationGuestInput = {
  countryCode: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  mcc: Scalars["String"]["input"];
  mncs: Array<Scalars["String"]["input"]>;
};

export type EditFederationHostInput = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  countryCode: Scalars["String"]["input"];
  federationName: Scalars["String"]["input"];
  hostUrl: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  mcc: Scalars["String"]["input"];
  mncs: Array<Scalars["String"]["input"]>;
  operatorName: Scalars["String"]["input"];
  siteId: Scalars["String"]["input"];
};

export enum EntityType {
  Block = "BLOCK",
  Device = "DEVICE",
  Unknown = "UNKNOWN"
}

export type Error = {
  __typename?: "Error";
  code?: Maybe<Scalars["Int"]["output"]>;
  message: Scalars["String"]["output"];
};

export type EventLogEntry = {
  __typename?: "EventLogEntry";
  message?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  resource?: Maybe<OktoResource>;
  time?: Maybe<Scalars["Time"]["output"]>;
  type?: Maybe<EventLogType>;
};

export type EventLogEntryEdge = {
  __typename?: "EventLogEntryEdge";
  cursor: Scalars["String"]["output"];
  node?: Maybe<EventLogEntry>;
};

export enum EventLogType {
  Normal = "NORMAL",
  Unknown = "UNKNOWN",
  Warning = "WARNING"
}

export type EventLogs = {
  __typename?: "EventLogs";
  edges: Array<Maybe<EventLogEntryEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type Federation = {
  __typename?: "Federation";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  federationUrl?: Maybe<Scalars["String"]["output"]>;
  mcc?: Maybe<Scalars["String"]["output"]>;
  mncs?: Maybe<Array<Scalars["String"]["output"]>>;
  operatorName?: Maybe<Scalars["String"]["output"]>;
};

export type FederationGuest = AuditLogEntity & {
  __typename?: "FederationGuest";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  federationName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mcc?: Maybe<Scalars["String"]["output"]>;
  mecSite?: Maybe<MecSite>;
  mncs?: Maybe<Array<Scalars["String"]["output"]>>;
  operatorName?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Status>;
};

export type FederationGuestInput = {
  countryCode: Scalars["String"]["input"];
  federationName: Scalars["String"]["input"];
  mcc: Scalars["String"]["input"];
  mecSite: Scalars["String"]["input"];
  mncs: Array<Scalars["String"]["input"]>;
  operatorName: Scalars["String"]["input"];
};

export type FederationHost = AuditLogEntity & {
  __typename?: "FederationHost";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  federationName?: Maybe<Scalars["String"]["output"]>;
  hostUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mcc?: Maybe<Scalars["String"]["output"]>;
  mncs?: Maybe<Array<Scalars["String"]["output"]>>;
  operatorName?: Maybe<Scalars["String"]["output"]>;
  siteId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Status>;
};

export type FederationHostInput = {
  clientId: Scalars["String"]["input"];
  clientSecret: Scalars["String"]["input"];
  countryCode: Scalars["String"]["input"];
  federationName: Scalars["String"]["input"];
  hostUrl: Scalars["String"]["input"];
  mcc: Scalars["String"]["input"];
  mncs: Array<Scalars["String"]["input"]>;
  operatorName: Scalars["String"]["input"];
  siteId: Scalars["String"]["input"];
};

export type FederationInput = {
  countryCode: Scalars["String"]["input"];
  federationUrl: Scalars["String"]["input"];
  mcc: Scalars["String"]["input"];
  mncs: Array<Scalars["String"]["input"]>;
  operatorName: Scalars["String"]["input"];
};

export type FieldParam = {
  __typename?: "FieldParam";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type GenericAuditLogEntity = AuditLogEntity & {
  __typename?: "GenericAuditLogEntity";
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type IpPool = {
  __typename?: "IPPool";
  chart?: Maybe<ChartKey>;
  cidr?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mecSites?: Maybe<Array<MecSite>>;
  status?: Maybe<Status>;
  subnets?: Maybe<Array<Subnet>>;
  type?: Maybe<IpPoolType>;
};

export enum IpPoolType {
  External = "EXTERNAL",
  Private = "PRIVATE",
  Shared = "SHARED",
  Unknown = "UNKNOWN"
}

export enum IpPoolTypeInput {
  External = "EXTERNAL",
  Private = "PRIVATE",
  Shared = "SHARED"
}

export type InfraBlockChart = {
  __typename?: "InfraBlockChart";
  config?: Maybe<Array<Maybe<ChartConfigField>>>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** nearbyone/overrides.yaml, or values.yaml if it doesn't exist, converted from YAML to JSON */
  overridesJson?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/overrides.schema.json, or null if this file doesn't exist */
  overridesSchema?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/overrides.yaml if present, values.yaml if not */
  overridesYaml?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<InfraChartType>;
  /** nearbyone/ui-edit.schema.json, nearbyone/ui.schema.json, or null */
  uiEditSchema?: Maybe<Scalars["String"]["output"]>;
  /** nearbyone/ui.schema.json, or null if this file doesn't exist */
  uiSchema?: Maybe<Scalars["String"]["output"]>;
  /** contents of values.schema.json, with defaults set to values from values.yaml */
  valuesSchema?: Maybe<Scalars["String"]["output"]>;
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export enum InfraChartType {
  ComputeResource = "COMPUTE_RESOURCE",
  IpPool = "IP_POOL",
  MecSite = "MEC_SITE",
  MnCore = "MN_CORE",
  MnCoreManager = "MN_CORE_MANAGER",
  MnGnb = "MN_GNB",
  MnNetwork = "MN_NETWORK",
  MnRanManager = "MN_RAN_MANAGER",
  OpgFederation = "OPG_FEDERATION",
  Subnet = "SUBNET",
  Unknown = "UNKNOWN"
}

export enum InstallStatus {
  Error = "ERROR",
  Installed = "INSTALLED",
  Pending = "PENDING",
  Unknown = "UNKNOWN"
}

export type KeyValue = {
  __typename?: "KeyValue";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type KeyValueArray = {
  __typename?: "KeyValueArray";
  key: Scalars["String"]["output"];
  values?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Label = {
  __typename?: "Label";
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type LatLng = {
  __typename?: "LatLng";
  lat: Scalars["Float"]["output"];
  lng: Scalars["Float"]["output"];
};

export type LatLngInput = {
  /** Degrees latitude */
  lat: Scalars["Float"]["input"];
  /** Degrees longitude */
  lng: Scalars["Float"]["input"];
};

export type LogEndpoint = {
  __typename?: "LogEndpoint";
  description?: Maybe<Scalars["String"]["output"]>;
  /** Hash of the log endpoint data hidden to the client */
  hash: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  source?: Maybe<LogSource>;
  sources?: Maybe<Array<Maybe<LogSource>>>;
};

export type LogEndpointSourceArgs = {
  id: Scalars["String"]["input"];
};

export type LogEntry = {
  __typename?: "LogEntry";
  log: Scalars["String"]["output"];
  metadata?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export enum LogEventType {
  Normal = "NORMAL",
  Warning = "WARNING"
}

export type LogSource = {
  __typename?: "LogSource";
  id: Scalars["String"]["output"];
  logs?: Maybe<Array<Maybe<LogEntry>>>;
  name: Scalars["String"]["output"];
};

export type LogSourceLogsArgs = {
  before?: InputMaybe<Scalars["Boolean"]["input"]>;
  logFilterSimpleQuery?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
};

export type MapSettings = {
  __typename?: "MapSettings";
  center?: Maybe<LatLng>;
  zoom?: Maybe<Scalars["Int"]["output"]>;
};

export type MecSite = {
  __typename?: "MecSite";
  chart?: Maybe<ChartKey>;
  cluster?: Maybe<Device>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  liveData?: Maybe<Array<Maybe<Connection>>>;
  status?: Maybe<Status>;
  subnets?: Maybe<Array<Maybe<Subnet>>>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type MecSiteLiveDataArgs = {
  org?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  cloudResource?: Maybe<Device>;
  createComputeResource: ComputeResource;
  createFederationGuest?: Maybe<FederationGuest>;
  createFederationHost?: Maybe<FederationHost>;
  createIPPool?: Maybe<IpPool>;
  createMecSite?: Maybe<MecSite>;
  createOrg?: Maybe<Org>;
  createSite?: Maybe<Site>;
  createSubnet?: Maybe<Subnet>;
  createUser?: Maybe<CreateUserResponse>;
  /** returns chart version that was removed */
  deleteBlockChart?: Maybe<Scalars["String"]["output"]>;
  deployMecChain?: Maybe<ServiceChain>;
  deployServiceChain?: Maybe<ServiceChain>;
  device?: Maybe<Device>;
  /** remove the User from an org */
  dismissUser?: Maybe<Scalars["ID"]["output"]>;
  editDevice?: Maybe<Device>;
  editFederation?: Maybe<Org>;
  editFederationGuest?: Maybe<FederationGuest>;
  editFederationHost?: Maybe<FederationHost>;
  editOrg?: Maybe<Org>;
  editSite?: Maybe<Site>;
  generateRecoveryLink?: Maybe<RecoveryLink>;
  mapIPPool?: Maybe<IpPool>;
  pinSubnet?: Maybe<Subnet>;
  provision?: Maybe<Device>;
  publishBlockChart?: Maybe<BlockChart>;
  recruitUser?: Maybe<User>;
  removeComputeResource?: Maybe<Scalars["ID"]["output"]>;
  removeDevice?: Maybe<Scalars["ID"]["output"]>;
  removeFederationGuest?: Maybe<Scalars["ID"]["output"]>;
  removeFederationHost?: Maybe<Scalars["ID"]["output"]>;
  removeIPPool?: Maybe<Scalars["ID"]["output"]>;
  removeMecChain?: Maybe<Scalars["ID"]["output"]>;
  removeMecSite?: Maybe<Scalars["ID"]["output"]>;
  removeOrg?: Maybe<Scalars["ID"]["output"]>;
  removeServiceChain?: Maybe<Scalars["ID"]["output"]>;
  removeSite?: Maybe<Scalars["ID"]["output"]>;
  removeSubnet?: Maybe<Scalars["ID"]["output"]>;
  /** delete the User entirely */
  removeUser?: Maybe<Scalars["ID"]["output"]>;
  rollbackServiceChain?: Maybe<ServiceChain>;
  setBlockRepo?: Maybe<Scalars["String"]["output"]>;
  setControlValue?: Maybe<BlockControl>;
  setCustomCompletions?: Maybe<Scalars["String"]["output"]>;
  setDefaultMapPosition?: Maybe<MapSettings>;
  setProvisionRepo?: Maybe<Scalars["String"]["output"]>;
  setTemplateRepo?: Maybe<Scalars["String"]["output"]>;
  shareMecSites?: Maybe<Array<Maybe<MecSite>>>;
  unmapIPPool?: Maybe<IpPool>;
  unpinSubnet?: Maybe<Subnet>;
  unshareMecSite?: Maybe<MecSite>;
  updateCloudResource?: Maybe<Device>;
  updateMecChain?: Maybe<ServiceChain>;
  updateProvision?: Maybe<Provision>;
  updateServiceChain?: Maybe<ServiceChain>;
};

export type MutationCloudResourceArgs = {
  input: CloudResourceInput;
};

export type MutationCreateComputeResourceArgs = {
  asset: ComputeResourceInput;
};

export type MutationCreateFederationGuestArgs = {
  guest: FederationGuestInput;
};

export type MutationCreateFederationHostArgs = {
  host: FederationHostInput;
};

export type MutationCreateIpPoolArgs = {
  input: CreateIpPoolInput;
};

export type MutationCreateMecSiteArgs = {
  input: CreateMecSiteInput;
};

export type MutationCreateOrgArgs = {
  org: OrgInput;
};

export type MutationCreateSiteArgs = {
  site: SiteInput;
};

export type MutationCreateSubnetArgs = {
  input: CreateSubnetInput;
};

export type MutationCreateUserArgs = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  org: Scalars["ID"]["input"];
};

export type MutationDeleteBlockChartArgs = {
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
};

export type MutationDeployMecChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  mecSite: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  subnet: Scalars["ID"]["input"];
};

export type MutationDeployServiceChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  name: Scalars["String"]["input"];
};

export type MutationDeviceArgs = {
  dev: DeviceInput;
};

export type MutationDismissUserArgs = {
  orgId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationEditDeviceArgs = {
  device: EditDeviceInput;
  id: Scalars["ID"]["input"];
};

export type MutationEditFederationArgs = {
  fed: FederationInput;
  id: Scalars["ID"]["input"];
};

export type MutationEditFederationGuestArgs = {
  info: EditFederationGuestInput;
};

export type MutationEditFederationHostArgs = {
  host: EditFederationHostInput;
};

export type MutationEditOrgArgs = {
  id: Scalars["ID"]["input"];
  org: OrgInput;
};

export type MutationEditSiteArgs = {
  id: Scalars["ID"]["input"];
  site: SiteInput;
};

export type MutationGenerateRecoveryLinkArgs = {
  user: Scalars["ID"]["input"];
};

export type MutationMapIpPoolArgs = {
  mecSite: Scalars["ID"]["input"];
  pool: Scalars["ID"]["input"];
};

export type MutationPinSubnetArgs = {
  mecSite: Scalars["ID"]["input"];
  subnet: Scalars["ID"]["input"];
};

export type MutationProvisionArgs = {
  input: ProvisionInput;
};

export type MutationPublishBlockChartArgs = {
  input: PublishBlockChartInput;
};

export type MutationRecruitUserArgs = {
  org: Scalars["ID"]["input"];
  user: Scalars["ID"]["input"];
};

export type MutationRemoveComputeResourceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveDeviceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveFederationGuestArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveFederationHostArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveIpPoolArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveMecChainArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveMecSiteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveOrgArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveServiceChainArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveSiteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveSubnetArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRollbackServiceChainArgs = {
  id: Scalars["ID"]["input"];
  revision: Scalars["Int"]["input"];
};

export type MutationSetBlockRepoArgs = {
  repo: RepoInput;
};

export type MutationSetControlValueArgs = {
  id: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationSetCustomCompletionsArgs = {
  completions: Scalars["String"]["input"];
};

export type MutationSetDefaultMapPositionArgs = {
  center: LatLngInput;
  zoom: Scalars["Int"]["input"];
};

export type MutationSetProvisionRepoArgs = {
  repo: RepoInput;
};

export type MutationSetTemplateRepoArgs = {
  repo: RepoInput;
};

export type MutationShareMecSitesArgs = {
  mecSites: Array<Scalars["ID"]["input"]>;
  org: Scalars["ID"]["input"];
};

export type MutationUnmapIpPoolArgs = {
  mecSite: Scalars["ID"]["input"];
  pool: Scalars["ID"]["input"];
};

export type MutationUnpinSubnetArgs = {
  mecSite: Scalars["ID"]["input"];
  subnet: Scalars["ID"]["input"];
};

export type MutationUnshareMecSiteArgs = {
  mecSite: Scalars["ID"]["input"];
  org: Scalars["ID"]["input"];
};

export type MutationUpdateCloudResourceArgs = {
  input: UpdateCloudResourceInput;
};

export type MutationUpdateMecChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  id: Scalars["ID"]["input"];
  mecSite: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateProvisionArgs = {
  input: UpdateProvisionInput;
};

export type MutationUpdateServiceChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type Node = {
  __typename?: "Node";
  adminStatus?: Maybe<AdminStatus>;
  id: Scalars["ID"]["output"];
  labels?: Maybe<Array<Label>>;
  name: Scalars["String"]["output"];
  packages?: Maybe<Array<Package>>;
  repositories?: Maybe<Array<Repository>>;
  specs?: Maybe<Array<NodeSpecs>>;
  status?: Maybe<NodeStatus>;
  workflows?: Maybe<Array<Workflow>>;
  workloads?: Maybe<Workload>;
};

export type NodeSpecs = {
  __typename?: "NodeSpecs";
  architecture?: Maybe<Scalars["String"]["output"]>;
  multiResources?: Maybe<Array<KeyValueArray>>;
  os?: Maybe<Scalars["String"]["output"]>;
  processor?: Maybe<Scalars["String"]["output"]>;
  resources?: Maybe<Array<KeyValue>>;
  system?: Maybe<Scalars["String"]["output"]>;
};

export enum NodeStatus {
  InSync = "IN_SYNC",
  OutOfSync = "OUT_OF_SYNC",
  Unknown = "UNKNOWN"
}

export type OktoResource = {
  __typename?: "OktoResource";
  id: Scalars["ID"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  logs?: Maybe<Scalars["String"]["output"]>;
  manifest?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  namespace?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Status>;
};

export type Org = AuditLogEntity &
  SiteTreeItem & {
    __typename?: "Org";
    description?: Maybe<Scalars["String"]["output"]>;
    /** fetch the devices of this site and all descendent sites */
    devices?: Maybe<DevicesResult>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    federation?: Maybe<Federation>;
    id: Scalars["ID"]["output"];
    sites?: Maybe<Array<Maybe<Site>>>;
    users?: Maybe<Array<Maybe<User>>>;
  };

export type OrgInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Package = {
  __typename?: "Package";
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status?: Maybe<InstallStatus>;
  version?: Maybe<Scalars["String"]["output"]>;
  versionState?: Maybe<VersionState>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor: Scalars["String"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor: Scalars["String"]["output"];
};

export type PaginationInput = {
  afterCursor?: InputMaybe<Scalars["String"]["input"]>;
  beforeCursor?: InputMaybe<Scalars["String"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Progress = {
  __typename?: "Progress";
  goal: Scalars["Int"]["output"];
  step: Scalars["Int"]["output"];
};

export type Provision = AuditLogEntity & {
  __typename?: "Provision";
  chart?: Maybe<ProvisionChart>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  inventory?: Maybe<Scalars["String"]["output"]>;
  lastUpdated?: Maybe<Scalars["Time"]["output"]>;
  logEndpoint?: Maybe<LogEndpoint>;
  logEndpoints?: Maybe<Array<Maybe<LogEndpoint>>>;
  phases?: Maybe<Array<Maybe<ProvisionPhase>>>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  siteTreeItem?: Maybe<Org | Site>;
  status?: Maybe<DeviceStatus>;
};

export type ProvisionLogEndpointArgs = {
  hash: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type ProvisionChart = {
  __typename?: "ProvisionChart";
  availableVersions?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** phases parsed from values.yaml e.g. baremetal software etc. */
  phases?: Maybe<Array<Maybe<ProvisionPhase>>>;
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export type ProvisionInput = {
  /** Chart name in Helm repo */
  chartName: Scalars["String"]["input"];
  /** Chart semantic version number */
  chartVersion: Scalars["String"]["input"];
  /** Display name of device to be shown in the UI */
  displayName: Scalars["String"]["input"];
  /** Geographical position of device */
  position: LatLngInput;
  provisionInventory: Scalars["String"]["input"];
  /** Site selector for Provisioner block deployment */
  provisionerSite: Scalars["ID"]["input"];
  /** Site that device belongs to */
  site: Scalars["ID"]["input"];
};

export type ProvisionPhase = {
  __typename?: "ProvisionPhase";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  progress?: Maybe<Progress>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  values?: Maybe<Scalars["String"]["output"]>;
};

export type PublishBlockChartInput = {
  chartMeta: ChartMetaInput;
  overridesYaml?: InputMaybe<Scalars["String"]["input"]>;
  templateName: Scalars["String"]["input"];
  templateVersion?: InputMaybe<Scalars["String"]["input"]>;
  valuesYaml: Scalars["String"]["input"];
};

export type Query = {
  __typename?: "Query";
  apiTokens?: Maybe<ApiTokens>;
  /** Return logs for a subject's Org between the start and end times */
  auditLogs?: Maybe<AuditLogs>;
  blockChart?: Maybe<BlockChart>;
  /** Return the latest versions of all Block Charts in the registry */
  blockCharts?: Maybe<Array<Maybe<BlockChart>>>;
  /** Return the values of a cloudResource provision chart */
  cloudResourceChart?: Maybe<CloudResourceChart>;
  /** Return the latest versions of all cloudResource Charts in the registry */
  cloudResourceCharts: Array<Maybe<CloudResourceChartListing>>;
  computeResources?: Maybe<Array<ComputeResource>>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  customCompletions?: Maybe<Scalars["String"]["output"]>;
  devices?: Maybe<DevicesResult>;
  /** Return logs for okto resources between the start and end times */
  eventLogs?: Maybe<EventLogs>;
  /** Return the federation guest with the given ID */
  federationGuest?: Maybe<FederationGuest>;
  /** Return a list of the federation guests */
  federationGuests?: Maybe<Array<Maybe<FederationGuest>>>;
  /** Return the federation host with the given ID */
  federationHost?: Maybe<FederationHost>;
  /** Return a list of the federation hosts */
  federationHosts?: Maybe<Array<Maybe<FederationHost>>>;
  /** Return a device provision with the given ID, including detail of Okto Resources */
  fetchProvision?: Maybe<Provision>;
  /** Return the a infrastrcture block chart of a specified type */
  infraBlockChart?: Maybe<InfraBlockChart>;
  /** Return all versions of all infrastructure charts of a given type in the registry */
  infraBlockCharts?: Maybe<Array<Maybe<ChartKey>>>;
  ipPools?: Maybe<Array<Maybe<IpPool>>>;
  mecChain?: Maybe<ServiceChain>;
  mecChains?: Maybe<Array<Maybe<ServiceChain>>>;
  /** Find MEC Sites satisfying the requirements of the specified MEC Block Charts */
  mecSites?: Maybe<Array<Maybe<MecSite>>>;
  oktoResourceLogs: Scalars["String"]["output"];
  /** Return the Org with the given ID */
  org?: Maybe<Org>;
  /** Return a list of all orgs */
  orgs?: Maybe<Array<Maybe<Org>>>;
  /** Return the baremetal / software values of a provision chart */
  provisionChart?: Maybe<ProvisionChart>;
  /** Return all versions of all provision Charts in the registry */
  provisionCharts?: Maybe<Array<Maybe<ChartKey>>>;
  /** Return all device provisions within a site. If org is empty, org from context will be used */
  provisions?: Maybe<Array<Provision>>;
  serviceChain?: Maybe<ServiceChain>;
  serviceChains?: Maybe<Array<Maybe<ServiceChain>>>;
  settings?: Maybe<Settings>;
  /** Return the Site with the given ID */
  site?: Maybe<Site>;
  templateChart?: Maybe<TemplateChart>;
  /** Return the latest versions of all Template Charts in the registry */
  templateCharts?: Maybe<Array<Maybe<TemplateChart>>>;
  /** Return the User with the given ID, or if the ID is null, the currently logged in user */
  user?: Maybe<User>;
  /** Return all users (super admin only) */
  users?: Maybe<Array<Maybe<User>>>;
  version?: Maybe<Version>;
};

export type QueryAuditLogsArgs = {
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<AuditLogEventType>>;
};

export type QueryBlockChartArgs = {
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryBlockChartsArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  chartType?: InputMaybe<BlockChartType>;
  vendors?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryCloudResourceChartArgs = {
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
};

export type QueryComputeResourcesArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  org?: InputMaybe<Scalars["ID"]["input"]>;
  site?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryConnectionsArgs = {
  entities?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryDevicesArgs = {
  bbox?: InputMaybe<BBox>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<TagInput>>;
};

export type QueryEventLogsArgs = {
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<EventLogType>>;
};

export type QueryFederationGuestArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFederationHostArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFetchProvisionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInfraBlockChartArgs = {
  name: Scalars["String"]["input"];
  type: InfraChartType;
  version: Scalars["String"]["input"];
};

export type QueryInfraBlockChartsArgs = {
  type: InfraChartType;
};

export type QueryIpPoolsArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  org?: InputMaybe<Scalars["ID"]["input"]>;
  type?: InputMaybe<IpPoolTypeInput>;
};

export type QueryMecChainArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMecChainsArgs = {
  mecSites?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  org?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryMecSitesArgs = {
  charts?: InputMaybe<Array<ChartKeyInput>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  org?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryOktoResourceLogsArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrgArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProvisionChartArgs = {
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
};

export type QueryProvisionsArgs = {
  org?: InputMaybe<Scalars["ID"]["input"]>;
  site: Scalars["ID"]["input"];
};

export type QueryServiceChainArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryServiceChainsArgs = {
  org?: InputMaybe<Scalars["ID"]["input"]>;
  sites?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QuerySiteArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTemplateChartArgs = {
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTemplateChartsArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  vendors?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUsersArgs = {
  group?: InputMaybe<Scalars["ID"]["input"]>;
};

export type RecoveryLink = {
  __typename?: "RecoveryLink";
  expiresAt?: Maybe<Scalars["Time"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
};

export type RepoInput = {
  password: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type RepoSettings = {
  __typename?: "RepoSettings";
  credentialsSet?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type Repository = {
  __typename?: "Repository";
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status?: Maybe<InstallStatus>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceChain = AuditLogEntity & {
  __typename?: "ServiceChain";
  blocks?: Maybe<Array<Maybe<Block>>>;
  createdAt?: Maybe<Scalars["Time"]["output"]>;
  history?: Maybe<Array<ServiceChain>>;
  id: Scalars["ID"]["output"];
  mecSite?: Maybe<MecSite>;
  name?: Maybe<Scalars["String"]["output"]>;
  org?: Maybe<Scalars["String"]["output"]>;
  owner?: Maybe<Scalars["String"]["output"]>;
  revision: Scalars["Int"]["output"];
  status?: Maybe<Status>;
};

export type ServiceChainHistoryArgs = {
  revision?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ServiceParam = {
  __typename?: "ServiceParam";
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type Settings = {
  __typename?: "Settings";
  block?: Maybe<RepoSettings>;
  map?: Maybe<MapSettings>;
  provision?: Maybe<RepoSettings>;
  template?: Maybe<RepoSettings>;
};

export type Site = AuditLogEntity &
  SiteTreeItem & {
    __typename?: "Site";
    ancestors?: Maybe<Array<Maybe<Site>>>;
    description?: Maybe<Scalars["String"]["output"]>;
    /** fetch the devices of this site and all descendent sites. org ID from context will be used if none is passed as arg */
    devices?: Maybe<DevicesResult>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    /** the original org the site was registered to */
    org?: Maybe<Org>;
    sites?: Maybe<Array<Maybe<Site>>>;
  };

export type SiteDevicesArgs = {
  org?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SiteInput = {
  ancestors?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SiteTreeItem = {
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  sites?: Maybe<Array<Maybe<Site>>>;
};

export type Specs = {
  __typename?: "Specs";
  cpu?: Maybe<Scalars["String"]["output"]>;
  gpu?: Maybe<Scalars["String"]["output"]>;
  memory?: Maybe<Scalars["String"]["output"]>;
  other?: Maybe<Array<KeyValue>>;
};

export enum Status {
  Deleting = "DELETING",
  Error = "ERROR",
  InSync = "IN_SYNC",
  Pending = "PENDING",
  Progressing = "PROGRESSING",
  Unknown = "UNKNOWN"
}

export type Subnet = {
  __typename?: "Subnet";
  chart?: Maybe<ChartKey>;
  cidr?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  mecSite?: Maybe<MecSite>;
  status?: Maybe<Status>;
  type?: Maybe<SubnetType>;
};

export enum SubnetType {
  InfraManaged = "INFRA_MANAGED",
  Managed = "MANAGED",
  Unknown = "UNKNOWN"
}

export enum SubnetTypeInput {
  InfraManaged = "INFRA_MANAGED",
  Managed = "MANAGED"
}

export type SyncSchedule = {
  __typename?: "SyncSchedule";
  lastCheck?: Maybe<Scalars["Time"]["output"]>;
  nextCheck?: Maybe<Scalars["Time"]["output"]>;
  policy?: Maybe<Scalars["String"]["output"]>;
};

export type Tag = {
  __typename?: "Tag";
  inc: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
};

export type TagInput = {
  inc: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
};

export type TelemetryUrl = {
  __typename?: "TelemetryUrl";
  displayName?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type TemplateChart = {
  __typename?: "TemplateChart";
  availableVersions?: Maybe<Array<Scalars["String"]["output"]>>;
  /** keywords field in Chart.yaml */
  categories?: Maybe<Array<Scalars["String"]["output"]>>;
  /** full contents of Chart.yaml */
  chartYaml?: Maybe<Scalars["String"]["output"]>;
  /** description field in Chart.yaml */
  description?: Maybe<Scalars["String"]["output"]>;
  /** annotations.displayName in Chart.yaml */
  displayName?: Maybe<Scalars["String"]["output"]>;
  /** Chart digest, used for client caching. Cannot fetch by ID! */
  id: Scalars["ID"]["output"];
  /** icon field in Chart.yaml */
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"]["output"];
  /** overrides.yaml, values.yaml if it doesn't exist */
  overridesYaml?: Maybe<Scalars["String"]["output"]>;
  /** values.yaml */
  valuesYaml?: Maybe<Scalars["String"]["output"]>;
  /** annotations.vendor field in Chart.yaml */
  vendor?: Maybe<Scalars["String"]["output"]>;
  /** version field in Chart.yaml */
  version: Scalars["String"]["output"];
};

export type UpdateCloudResourceInput = {
  blockVersion?: InputMaybe<Scalars["String"]["input"]>;
  deviceSite: Scalars["ID"]["input"];
  displayName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  position: LatLngInput;
  providerConfig?: InputMaybe<Array<CloudResourceConfigFieldInput>>;
};

export type UpdateProvisionInput = {
  /** Chart semantic version number to update */
  chartVersion?: InputMaybe<Scalars["String"]["input"]>;
  /** Display name of device to be shown in the UI */
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  /** Geographical position of device */
  position?: InputMaybe<LatLngInput>;
  /** Provision inventory to update */
  provisionInventory?: InputMaybe<Scalars["String"]["input"]>;
  /** Site selector for Provisioner block deployment to update */
  provisionerSite?: InputMaybe<Scalars["ID"]["input"]>;
};

export type User = AuditLogEntity & {
  __typename?: "User";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  orgs?: Maybe<Array<Maybe<Org>>>;
};

export type UserOrgsArgs = {
  org?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ValuesField = {
  __typename?: "ValuesField";
  label: Scalars["String"]["output"];
  redacted?: Maybe<Scalars["Boolean"]["output"]>;
  required: Scalars["Boolean"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type Version = {
  __typename?: "Version";
  app?: Maybe<Scalars["String"]["output"]>;
  build?: Maybe<Scalars["String"]["output"]>;
};

export enum VersionState {
  Locked = "LOCKED",
  Unknown = "UNKNOWN",
  Unlocked = "UNLOCKED"
}

export type Workflow = {
  __typename?: "Workflow";
  name: Scalars["String"]["output"];
  status?: Maybe<WorkflowStatus>;
  type?: Maybe<WorkflowType>;
};

export enum WorkflowStatus {
  Hello = "HELLO",
  Unknown = "UNKNOWN"
}

export enum WorkflowType {
  HelmChart = "HELM_CHART",
  Unknown = "UNKNOWN",
  VirtualMachine = "VIRTUAL_MACHINE"
}

export type Workload = {
  __typename?: "Workload";
  failed?: Maybe<Scalars["Int"]["output"]>;
  running?: Maybe<Scalars["Int"]["output"]>;
  scheduled?: Maybe<Scalars["Int"]["output"]>;
  succeeded?: Maybe<Scalars["Int"]["output"]>;
};

export type VersionQueryVariables = Exact<{ [key: string]: never }>;

export type VersionQuery = {
  __typename?: "Query";
  version?: { __typename?: "Version"; app?: string | null; build?: string | null } | null;
};

export type FetchCompletionsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCompletionsQuery = { __typename?: "Query"; customCompletions?: string | null };

export type SetCompletionsMutationVariables = Exact<{
  json: Scalars["String"]["input"];
}>;

export type SetCompletionsMutation = {
  __typename?: "Mutation";
  setCustomCompletions?: string | null;
};

export type BlockRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type BlockRepoUrlQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    block?: {
      __typename?: "RepoSettings";
      url?: string | null;
      credentialsSet?: boolean | null;
    } | null;
  } | null;
};

export type ProvisionRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type ProvisionRepoUrlQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    provision?: {
      __typename?: "RepoSettings";
      url?: string | null;
      credentialsSet?: boolean | null;
    } | null;
  } | null;
};

export type SetBlockRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetBlockRepoMutation = { __typename?: "Mutation"; setBlockRepo?: string | null };

export type SetProvisionRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetProvisionRepoMutation = {
  __typename?: "Mutation";
  setProvisionRepo?: string | null;
};

export type SetTemplateRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetTemplateRepoMutation = { __typename?: "Mutation"; setTemplateRepo?: string | null };

export type TemplateRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type TemplateRepoUrlQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    template?: {
      __typename?: "RepoSettings";
      url?: string | null;
      credentialsSet?: boolean | null;
    } | null;
  } | null;
};

export type DevicesQueryVariables = Exact<{
  tags?: InputMaybe<Array<TagInput> | TagInput>;
  bbox?: InputMaybe<BBox>;
}>;

export type DevicesQuery = {
  __typename?: "Query";
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{
      __typename?: "Device";
      id: string;
      displayName?: string | null;
      tags?: Array<string> | null;
      status?: DeviceStatus | null;
      deviceType?: string | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        category: string;
        kind: string;
        name: string;
        source: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type SetMapMutationVariables = Exact<{
  center: LatLngInput;
  zoom: Scalars["Int"]["input"];
}>;

export type SetMapMutation = {
  __typename?: "Mutation";
  setDefaultMapPosition?: {
    __typename?: "MapSettings";
    zoom?: number | null;
    center?: { __typename?: "LatLng"; lat: number; lng: number } | null;
  } | null;
};

export type MapSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MapSettingsQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    map?: {
      __typename?: "MapSettings";
      zoom?: number | null;
      center?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    } | null;
  } | null;
};

export type AddOrgMutationVariables = Exact<{
  org: OrgInput;
}>;

export type AddOrgMutation = {
  __typename?: "Mutation";
  createOrg?: {
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    description?: string | null;
  } | null;
};

export type RemoveOrgMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveOrgMutation = { __typename?: "Mutation"; removeOrg?: string | null };

export type AllUsersGroupsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type AllUsersGroupsQuery = {
  __typename?: "Query";
  users?: Array<{
    __typename?: "User";
    id: string;
    name?: string | null;
    email?: string | null;
  } | null> | null;
};

export type AddUserMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  org: Scalars["ID"]["input"];
}>;

export type AddUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "CreateUserResponse";
    user?: { __typename?: "User"; id: string; name?: string | null; email?: string | null } | null;
    error?: { __typename?: "Error"; code?: number | null; message: string } | null;
  } | null;
};

export type AllUsersTableQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersTableQuery = {
  __typename?: "Query";
  users?: Array<{
    __typename?: "User";
    id: string;
    name?: string | null;
    email?: string | null;
    orgs?: Array<{ __typename?: "Org"; id: string; displayName?: string | null } | null> | null;
  } | null> | null;
};

export type DismissUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  orgId: Scalars["ID"]["input"];
}>;

export type DismissUserMutation = { __typename?: "Mutation"; dismissUser?: string | null };

export type OrgUsersTableQueryVariables = Exact<{
  org: Scalars["ID"]["input"];
}>;

export type OrgUsersTableQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    users?: Array<{
      __typename?: "User";
      id: string;
      name?: string | null;
      email?: string | null;
      orgs?: Array<{ __typename?: "Org"; id: string } | null> | null;
    } | null> | null;
  } | null;
};

export type RecruitUserMutationVariables = Exact<{
  user: Scalars["ID"]["input"];
  org: Scalars["ID"]["input"];
}>;

export type RecruitUserMutation = {
  __typename?: "Mutation";
  recruitUser?: {
    __typename?: "User";
    id: string;
    name?: string | null;
    email?: string | null;
  } | null;
};

export type RecruitableUserQueryVariables = Exact<{
  user: Scalars["ID"]["input"];
}>;

export type RecruitableUserQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; id: string; name?: string | null; email?: string | null } | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation"; removeUser?: string | null };

export type GenerateRecoveryLinkMutationVariables = Exact<{
  user: Scalars["ID"]["input"];
}>;

export type GenerateRecoveryLinkMutation = {
  __typename?: "Mutation";
  generateRecoveryLink?: {
    __typename?: "RecoveryLink";
    link?: string | null;
    expiresAt?: any | null;
  } | null;
};

export type OrgSelectQueryVariables = Exact<{ [key: string]: never }>;

export type OrgSelectQuery = {
  __typename?: "Query";
  orgs?: Array<{ __typename?: "Org"; id: string; displayName?: string | null } | null> | null;
};

export type RemoveUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveUserMutation = { __typename?: "Mutation"; removeUser?: string | null };

export type UserNameQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UserNameQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; id: string; name?: string | null } | null;
};

export type DeleteChartMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
}>;

export type DeleteChartMutation = { __typename?: "Mutation"; deleteBlockChart?: string | null };

export type EditOrgMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  org: OrgInput;
}>;

export type EditOrgMutation = {
  __typename?: "Mutation";
  editOrg?: {
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    description?: string | null;
  } | null;
};

export type FetchOrgQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchOrgQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    description?: string | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{
        __typename?: "Device";
        id: string;
        displayName?: string | null;
        status?: DeviceStatus | null;
        tags?: Array<string> | null;
        position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
      }> | null;
    } | null;
  } | null;
};

export type FetchOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrganizationsQuery = {
  __typename?: "Query";
  orgs?: Array<{
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    description?: string | null;
  } | null> | null;
};

export type InstancesQueryVariables = Exact<{ [key: string]: never }>;

export type InstancesQuery = {
  __typename?: "Query";
  serviceChains?: Array<{
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    status?: Status | null;
    createdAt?: any | null;
  } | null> | null;
};

export type OrgNameQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrgNameQuery = {
  __typename?: "Query";
  org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
};

export type OrgTitleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrgTitleQuery = {
  __typename?: "Query";
  org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
};

export type SiteInfoQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SiteInfoQuery = {
  __typename?: "Query";
  site?: { __typename?: "Site"; id: string; displayName?: string | null } | null;
};

export type FetchParentSiteQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchParentSiteQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
    ancestors?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type GoogleMapsApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleMapsApiTokenQuery = {
  __typename?: "Query";
  apiTokens?: { __typename?: "ApiTokens"; id: string; googleMaps?: string | null } | null;
};

export type MapboxApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type MapboxApiTokenQuery = {
  __typename?: "Query";
  apiTokens?: { __typename?: "ApiTokens"; id: string; mapbox?: string | null } | null;
};

export type FetchPanelLogsQueryVariables = Exact<{
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  pageSize: Scalars["Int"]["input"];
  eventTypes?: InputMaybe<Array<AuditLogEventType> | AuditLogEventType>;
}>;

export type FetchPanelLogsQuery = {
  __typename?: "Query";
  auditLogs?: {
    __typename?: "AuditLogs";
    edges: Array<{
      __typename?: "AuditLogEntryEdge";
      node?: {
        __typename?: "AuditLogEntry";
        time?: any | null;
        message?: string | null;
        type?: AuditLogEventType | null;
        reason?: string | null;
        entityType?: AuditLogEntityType | null;
        org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
        user?: { __typename?: "User"; id: string; email?: string | null } | null;
        entity?:
          | { __typename?: "BlockChart"; id: string; blockChartName: string }
          | { __typename?: "Device"; id: string; name?: string | null }
          | { __typename?: "FederationGuest"; id: string; name?: string | null }
          | { __typename?: "FederationHost"; id: string; name?: string | null }
          | { __typename?: "GenericAuditLogEntity"; name?: string | null; id: string }
          | { __typename?: "Org"; id: string; name?: string | null }
          | {
              __typename?: "Provision";
              status?: DeviceStatus | null;
              id: string;
              name?: string | null;
            }
          | { __typename?: "ServiceChain"; name?: string | null; revision: number; id: string }
          | { __typename?: "Site"; id: string; name?: string | null }
          | { __typename?: "User"; id: string; name?: string | null }
          | null;
      } | null;
    } | null>;
  } | null;
};

export type DeployMecChainMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  blocks?: InputMaybe<Array<BlockInput> | BlockInput>;
  mecSite: Scalars["ID"]["input"];
  subnet: Scalars["ID"]["input"];
}>;

export type DeployMecChainMutation = {
  __typename?: "Mutation";
  deployMecChain?: {
    __typename?: "ServiceChain";
    id: string;
    name?: string | null;
    createdAt?: any | null;
  } | null;
};

export type FetchMecChartQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchMecChartQuery = {
  __typename?: "Query";
  blockChart?: {
    __typename?: "BlockChart";
    id: string;
    displayName?: string | null;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    overridesJson?: string | null;
    overridesYaml?: string | null;
    overridesSchema?: string | null;
    uiSchema?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
    availableVersions?: Array<string> | null;
  } | null;
};

export type GetMecSitesDropdownQueryVariables = Exact<{
  charts?: InputMaybe<Array<ChartKeyInput> | ChartKeyInput>;
}>;

export type GetMecSitesDropdownQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
  } | null> | null;
};

export type GetSubnetsDropdownQueryVariables = Exact<{
  mecSite: Scalars["ID"]["input"];
}>;

export type GetSubnetsDropdownQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    subnets?: Array<{
      __typename?: "Subnet";
      id: string;
      displayName?: string | null;
      type?: SubnetType | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateMecChainMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  blocks?: InputMaybe<Array<BlockInput> | BlockInput>;
  mecSite: Scalars["ID"]["input"];
}>;

export type UpdateMecChainMutation = {
  __typename?: "Mutation";
  updateMecChain?: {
    __typename?: "ServiceChain";
    id: string;
    name?: string | null;
    createdAt?: any | null;
  } | null;
};

export type UpdateMecChainParamsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UpdateMecChainParamsQuery = {
  __typename?: "Query";
  mecChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    mecSite?: { __typename?: "MecSite"; id: string } | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      values?: string | null;
      valuesJson?: string | null;
      selectedVersion?: string | null;
      chart?: {
        __typename?: "BlockChart";
        id: string;
        version: string;
        name: string;
        availableVersions?: Array<string> | null;
        overridesSchema?: string | null;
        uiEditSchema?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSimpleMecSitesQueryVariables = Exact<{
  org?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type GetSimpleMecSitesQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
  } | null> | null;
};

export type FetchMecSiteDetailsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchMecSiteDetailsQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
    description?: string | null;
    tags?: Array<string> | null;
    status?: Status | null;
    chart?: { __typename?: "ChartKey"; name: string; version: string } | null;
    cluster?: {
      __typename?: "Device";
      id: string;
      displayName?: string | null;
      status?: DeviceStatus | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    } | null;
  } | null> | null;
};

export type FetchMecSitesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchMecSitesQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
    description?: string | null;
    tags?: Array<string> | null;
    status?: Status | null;
    chart?: { __typename?: "ChartKey"; name: string; version: string } | null;
    cluster?: {
      __typename?: "Device";
      id: string;
      displayName?: string | null;
      status?: DeviceStatus | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    } | null;
  } | null> | null;
};

export type CreateMecSiteMutationVariables = Exact<{
  site: CreateMecSiteInput;
}>;

export type CreateMecSiteMutation = {
  __typename?: "Mutation";
  createMecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
};

export type FetchCloudPlatformsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchCloudPlatformsQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{
        __typename?: "Device";
        id: string;
        displayName?: string | null;
        deviceType?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type FetchMecSiteChartsQueryVariables = Exact<{
  type: InfraChartType;
}>;

export type FetchMecSiteChartsQuery = {
  __typename?: "Query";
  infraBlockCharts?: Array<{
    __typename?: "ChartKey";
    name: string;
    version: string;
  } | null> | null;
};

export type FetchProfileConfigQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
  type: InfraChartType;
}>;

export type FetchProfileConfigQuery = {
  __typename?: "Query";
  infraBlockChart?: {
    __typename?: "InfraBlockChart";
    name: string;
    version: string;
    type?: InfraChartType | null;
    config?: Array<{
      __typename?: "ChartConfigField";
      label: string;
      value?: string | null;
      type?: string | null;
      required: boolean;
      redacted?: boolean | null;
    } | null> | null;
  } | null;
};

export type RemoveMecSiteMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveMecSiteMutation = { __typename?: "Mutation"; removeMecSite?: string | null };

export type FetchIpPoolsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchIpPoolsQuery = {
  __typename?: "Query";
  externalPools?: Array<{
    __typename?: "IPPool";
    id: string;
    displayName?: string | null;
    description?: string | null;
    status?: Status | null;
    cidr?: string | null;
    type?: IpPoolType | null;
    subnets?: Array<{ __typename?: "Subnet"; id: string }> | null;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null> | null;
  sharedPools?: Array<{
    __typename?: "IPPool";
    id: string;
    displayName?: string | null;
    description?: string | null;
    status?: Status | null;
    cidr?: string | null;
    type?: IpPoolType | null;
    subnets?: Array<{ __typename?: "Subnet"; id: string }> | null;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null> | null;
  privatePools?: Array<{
    __typename?: "IPPool";
    id: string;
    displayName?: string | null;
    description?: string | null;
    status?: Status | null;
    cidr?: string | null;
    type?: IpPoolType | null;
    subnets?: Array<{ __typename?: "Subnet"; id: string }> | null;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null> | null;
};

export type FetchPoolDetailsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchPoolDetailsQuery = {
  __typename?: "Query";
  ipPools?: Array<{
    __typename?: "IPPool";
    id: string;
    displayName?: string | null;
    description?: string | null;
    status?: Status | null;
    cidr?: string | null;
    type?: IpPoolType | null;
    subnets?: Array<{
      __typename?: "Subnet";
      id: string;
      type?: SubnetType | null;
      displayName?: string | null;
      description?: string | null;
      cidr?: string | null;
      status?: Status | null;
      mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
    }> | null;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null> | null;
};

export type MapIpPoolMutationVariables = Exact<{
  pool: Scalars["ID"]["input"];
  mecSite: Scalars["ID"]["input"];
}>;

export type MapIpPoolMutation = {
  __typename?: "Mutation";
  mapIPPool?: {
    __typename?: "IPPool";
    id: string;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null;
};

export type UnmapIpPoolMutationVariables = Exact<{
  pool: Scalars["ID"]["input"];
  mecSite: Scalars["ID"]["input"];
}>;

export type UnmapIpPoolMutation = {
  __typename?: "Mutation";
  unmapIPPool?: {
    __typename?: "IPPool";
    id: string;
    mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
  } | null;
};

export type CreateIpPoolMutationVariables = Exact<{
  input: CreateIpPoolInput;
}>;

export type CreateIpPoolMutation = {
  __typename?: "Mutation";
  createIPPool?: {
    __typename?: "IPPool";
    id: string;
    displayName?: string | null;
    description?: string | null;
    type?: IpPoolType | null;
    status?: Status | null;
    cidr?: string | null;
  } | null;
};

export type IpPoolFragmentFragment = {
  __typename?: "IPPool";
  id: string;
  displayName?: string | null;
  description?: string | null;
  status?: Status | null;
  cidr?: string | null;
  type?: IpPoolType | null;
  mecSites?: Array<{ __typename?: "MecSite"; id: string; displayName?: string | null }> | null;
};

export type RemoveIpPoolMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveIpPoolMutation = { __typename?: "Mutation"; removeIPPool?: string | null };

export type RemoveSubnetMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveSubnetMutation = { __typename?: "Mutation"; removeSubnet?: string | null };

export type CreateSubnetMutationVariables = Exact<{
  input: CreateSubnetInput;
}>;

export type CreateSubnetMutation = {
  __typename?: "Mutation";
  createSubnet?: {
    __typename?: "Subnet";
    id: string;
    type?: SubnetType | null;
    displayName?: string | null;
    description?: string | null;
    cidr?: string | null;
    status?: Status | null;
    mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
  } | null;
};

export type PinSubnetMutationVariables = Exact<{
  subnet: Scalars["ID"]["input"];
  mecSite: Scalars["ID"]["input"];
}>;

export type PinSubnetMutation = {
  __typename?: "Mutation";
  pinSubnet?: {
    __typename?: "Subnet";
    id: string;
    mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
  } | null;
};

export type SubnetTableFieldsFragment = {
  __typename?: "Subnet";
  id: string;
  type?: SubnetType | null;
  displayName?: string | null;
  description?: string | null;
  cidr?: string | null;
  status?: Status | null;
  mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
};

export type UnpinSubnetMutationVariables = Exact<{
  subnet: Scalars["ID"]["input"];
  mecSite: Scalars["ID"]["input"];
}>;

export type UnpinSubnetMutation = {
  __typename?: "Mutation";
  unpinSubnet?: { __typename?: "Subnet"; id: string } | null;
};

export type FetchMecSiteLiveDataQueryVariables = Exact<{ [key: string]: never }>;

export type FetchMecSiteLiveDataQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
    liveData?: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      kind: string;
      category: string;
      source: string;
    } | null> | null;
  } | null> | null;
};

export type FetchMecChainInfoQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchMecChainInfoQuery = {
  __typename?: "Query";
  mecChain?: {
    __typename?: "ServiceChain";
    id: string;
    name?: string | null;
    revision: number;
    createdAt?: any | null;
    mecSite?: { __typename?: "MecSite"; id: string } | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      status?: Status | null;
      values?: string | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        name: string;
        kind: string;
        category: string;
        source: string;
      } | null> | null;
      chart?: {
        __typename?: "BlockChart";
        id: string;
        name: string;
        description?: string | null;
        logoUrl?: string | null;
        vendor?: string | null;
        version: string;
      } | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
        manifest?: string | null;
      } | null> | null;
      controls?: Array<{
        __typename?: "BlockControl";
        id: string;
        displayName?: string | null;
        value?: string | null;
        options?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FetchMecChainRevisionQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  revision?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FetchMecChainRevisionQuery = {
  __typename?: "Query";
  mecChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    history?: Array<{
      __typename?: "ServiceChain";
      id: string;
      revision: number;
      blocks?: Array<{
        __typename?: "Block";
        id: string;
        chainRevision: number;
        displayName?: string | null;
        status?: Status | null;
        values?: string | null;
        connections?: Array<{
          __typename?: "Connection";
          id: string;
          name: string;
          kind: string;
          category: string;
          source: string;
        } | null> | null;
        chart?: {
          __typename?: "BlockChart";
          id: string;
          name: string;
          description?: string | null;
          logoUrl?: string | null;
          vendor?: string | null;
          version: string;
        } | null;
        resources?: Array<{
          __typename?: "OktoResource";
          id: string;
          name?: string | null;
          kind?: string | null;
          status?: Status | null;
          manifest?: string | null;
        } | null> | null;
        controls?: Array<{
          __typename?: "BlockControl";
          id: string;
          displayName?: string | null;
          value?: string | null;
          options?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type RemoveMecChainMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveMecChainMutation = { __typename?: "Mutation"; removeMecChain?: string | null };

export type MecChainsQueryVariables = Exact<{
  org: Scalars["ID"]["input"];
  mecSites?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
}>;

export type MecChainsQuery = {
  __typename?: "Query";
  mecChains?: Array<{
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    status?: Status | null;
    createdAt?: any | null;
    mecSite?: { __typename?: "MecSite"; id: string } | null;
  } | null> | null;
};

export type SimpleMecSiteQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SimpleMecSiteQuery = {
  __typename?: "Query";
  mecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
  } | null> | null;
};

export type ShareMecSitesMutationVariables = Exact<{
  mecSites: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
  org: Scalars["ID"]["input"];
}>;

export type ShareMecSitesMutation = {
  __typename?: "Mutation";
  shareMecSites?: Array<{
    __typename?: "MecSite";
    id: string;
    displayName?: string | null;
  } | null> | null;
};

export type UnshareMecSiteMutationVariables = Exact<{
  mecSite: Scalars["ID"]["input"];
  org: Scalars["ID"]["input"];
}>;

export type UnshareMecSiteMutation = {
  __typename?: "Mutation";
  unshareMecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
};

export type FetchInfraBlockChartQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
  type: InfraChartType;
}>;

export type FetchInfraBlockChartQuery = {
  __typename?: "Query";
  infraBlockChart?: {
    __typename?: "InfraBlockChart";
    name: string;
    version: string;
    type?: InfraChartType | null;
    config?: Array<{
      __typename?: "ChartConfigField";
      label: string;
      value?: string | null;
      type?: string | null;
      required: boolean;
      redacted?: boolean | null;
    } | null> | null;
  } | null;
};

export type FetchInfraChartListQueryVariables = Exact<{
  type: InfraChartType;
}>;

export type FetchInfraChartListQuery = {
  __typename?: "Query";
  infraBlockCharts?: Array<{
    __typename?: "ChartKey";
    name: string;
    version: string;
  } | null> | null;
};

export type LiveDataQueryVariables = Exact<{
  entity: Scalars["ID"]["input"];
}>;

export type LiveDataQuery = {
  __typename?: "Query";
  connections?: Array<{
    __typename?: "Connection";
    id: string;
    name: string;
    category: string;
    source: string;
  } | null> | null;
};

export type DeviceSearchQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<TagInput> | TagInput>;
}>;

export type DeviceSearchQuery = {
  __typename?: "Query";
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{
      __typename?: "Device";
      id: string;
      displayName?: string | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    }> | null;
  } | null;
};

export type FetchCredentialsQueryVariables = Exact<{
  guest: Scalars["ID"]["input"];
}>;

export type FetchCredentialsQuery = {
  __typename?: "Query";
  connections?: Array<{
    __typename?: "Connection";
    id: string;
    source: string;
    category: string;
    kind: string;
  } | null> | null;
};

export type AddFederationGuestMutationVariables = Exact<{
  guest: FederationGuestInput;
}>;

export type AddFederationGuestMutation = {
  __typename?: "Mutation";
  createFederationGuest?: {
    __typename?: "FederationGuest";
    id: string;
    federationName?: string | null;
    operatorName?: string | null;
    countryCode?: string | null;
    mcc?: string | null;
    status?: Status | null;
  } | null;
};

export type EditFederationGuestMutationVariables = Exact<{
  info: EditFederationGuestInput;
}>;

export type EditFederationGuestMutation = {
  __typename?: "Mutation";
  editFederationGuest?: {
    __typename?: "FederationGuest";
    id: string;
    federationName?: string | null;
    operatorName?: string | null;
    countryCode?: string | null;
    mcc?: string | null;
    mncs?: Array<string> | null;
    status?: Status | null;
  } | null;
};

export type FederationGuestQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FederationGuestQuery = {
  __typename?: "Query";
  federationGuest?: {
    __typename?: "FederationGuest";
    id: string;
    federationName?: string | null;
    operatorName?: string | null;
    countryCode?: string | null;
    mcc?: string | null;
    mncs?: Array<string> | null;
    status?: Status | null;
    mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
  } | null;
};

export type FederationGuestsQueryVariables = Exact<{ [key: string]: never }>;

export type FederationGuestsQuery = {
  __typename?: "Query";
  federationGuests?: Array<{
    __typename?: "FederationGuest";
    id: string;
    federationName?: string | null;
    operatorName?: string | null;
    countryCode?: string | null;
    mcc?: string | null;
    status?: Status | null;
    mecSite?: { __typename?: "MecSite"; id: string; displayName?: string | null } | null;
  } | null> | null;
};

export type RemoveFederationGuestMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveFederationGuestMutation = {
  __typename?: "Mutation";
  removeFederationGuest?: string | null;
};

export type EditFederationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fed: FederationInput;
}>;

export type EditFederationMutation = {
  __typename?: "Mutation";
  editFederation?: {
    __typename?: "Org";
    id: string;
    federation?: {
      __typename?: "Federation";
      operatorName?: string | null;
      countryCode?: string | null;
      mcc?: string | null;
      mncs?: Array<string> | null;
      federationUrl?: string | null;
    } | null;
  } | null;
};

export type OrgIdentityQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrgIdentityQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    federation?: {
      __typename?: "Federation";
      operatorName?: string | null;
      countryCode?: string | null;
      mcc?: string | null;
      mncs?: Array<string> | null;
      federationUrl?: string | null;
    } | null;
  } | null;
};

export type EditSiteDisplayNameMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  site: SiteInput;
}>;

export type EditSiteDisplayNameMutation = {
  __typename?: "Mutation";
  editSite?: { __typename?: "Site"; id: string; displayName?: string | null } | null;
};

export type FetchOrgInformationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchOrgInformationQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{ __typename?: "Device"; id: string }> | null;
    } | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type FetchSiteQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchSiteQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    description?: string | null;
    ancestors?: Array<{
      __typename?: "Site";
      id: string;
      displayName?: string | null;
    } | null> | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{
        __typename?: "Device";
        id: string;
        displayName?: string | null;
        status?: DeviceStatus | null;
        tags?: Array<string> | null;
        position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
      }> | null;
    } | null;
  } | null;
};

export type FetchSiteInformationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchSiteInformationQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    ancestors?: Array<{ __typename?: "Site"; id: string } | null> | null;
    org?: { __typename?: "Org"; id: string } | null;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{ __typename?: "Device"; id: string }> | null;
    } | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type AddSiteMutationVariables = Exact<{
  site: SiteInput;
}>;

export type AddSiteMutation = {
  __typename?: "Mutation";
  createSite?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    description?: string | null;
    ancestors?: Array<{
      __typename?: "Site";
      id: string;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type SiteTreeItemQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SiteTreeItemQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    ancestors?: Array<{ __typename?: "Site"; id: string } | null> | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type SiteTreeOrgQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SiteTreeOrgQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    displayName?: string | null;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type UpdateCloudResourceDeviceMutationVariables = Exact<{
  input: UpdateCloudResourceInput;
}>;

export type UpdateCloudResourceDeviceMutation = {
  __typename?: "Mutation";
  updateCloudResource?: { __typename?: "Device"; id: string } | null;
};

export type FetchCloudResourceConfigQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
}>;

export type FetchCloudResourceConfigQuery = {
  __typename?: "Query";
  cloudResourceChart?: {
    __typename?: "CloudResourceChart";
    name: string;
    version: string;
    originalConfig?: Array<{
      __typename?: "ValuesField";
      label: string;
      value?: string | null;
      type?: string | null;
      required: boolean;
      redacted?: boolean | null;
    }> | null;
  } | null;
};

export type ListCloudResourceChartsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCloudResourceChartsQuery = {
  __typename?: "Query";
  cloudResourceCharts: Array<{
    __typename?: "CloudResourceChartListing";
    displayName: string;
    key: { __typename?: "ChartKey"; name: string; version: string };
  } | null>;
};

export type ProvisionCloudResourceMutationVariables = Exact<{
  input: CloudResourceInput;
}>;

export type ProvisionCloudResourceMutation = {
  __typename?: "Mutation";
  cloudResource?: { __typename?: "Device"; id: string } | null;
};

export type DeviceSpecsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeviceSpecsQuery = {
  __typename?: "Query";
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{ __typename?: "Device"; id: string; specs?: string | null }> | null;
  } | null;
};

export type DeviceViewQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeviceViewQuery = {
  __typename?: "Query";
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{
      __typename?: "Device";
      site?: string | null;
      metadata?: string | null;
      id: string;
      displayName?: string | null;
      tags?: Array<string> | null;
      status?: DeviceStatus | null;
      deviceType?: string | null;
      progress?: { __typename?: "Progress"; step: number; goal: number } | null;
      cloudResourceChart?: {
        __typename?: "CloudResourceChart";
        name: string;
        version: string;
      } | null;
      telemetryUrl?: Array<{
        __typename?: "TelemetryUrl";
        displayName?: string | null;
        url: string;
      }> | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        category: string;
        kind: string;
        name: string;
        source: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type AddDeviceMutationVariables = Exact<{
  dev: DeviceInput;
}>;

export type AddDeviceMutation = {
  __typename?: "Mutation";
  device?: {
    __typename?: "Device";
    id: string;
    displayName?: string | null;
    tags?: Array<string> | null;
    status?: DeviceStatus | null;
    deviceType?: string | null;
    position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    connections?: Array<{
      __typename?: "Connection";
      id: string;
      category: string;
      kind: string;
      name: string;
      source: string;
    } | null> | null;
  } | null;
};

export type EditDeviceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  device: EditDeviceInput;
}>;

export type EditDeviceMutation = {
  __typename?: "Mutation";
  editDevice?: {
    __typename?: "Device";
    id: string;
    displayName?: string | null;
    tags?: Array<string> | null;
    status?: DeviceStatus | null;
    deviceType?: string | null;
    position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    connections?: Array<{
      __typename?: "Connection";
      id: string;
      category: string;
      kind: string;
      name: string;
      source: string;
    } | null> | null;
  } | null;
};

export type EditableDeviceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type EditableDeviceQuery = {
  __typename?: "Query";
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{
      __typename?: "Device";
      site?: string | null;
      id: string;
      displayName?: string | null;
      tags?: Array<string> | null;
      status?: DeviceStatus | null;
      deviceType?: string | null;
      cloudResourceChart?: {
        __typename?: "CloudResourceChart";
        name: string;
        version: string;
        latestConfig?: Array<{
          __typename?: "ValuesField";
          label: string;
          value?: string | null;
          type?: string | null;
          required: boolean;
          redacted?: boolean | null;
        }> | null;
      } | null;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        category: string;
        kind: string;
        name: string;
        source: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type FetchOrgDevicesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchOrgDevicesQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{
        __typename?: "Device";
        id: string;
        displayName?: string | null;
        tags?: Array<string> | null;
        site?: string | null;
        status?: DeviceStatus | null;
        deviceType?: string | null;
        position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
        cloudResourceChart?: {
          __typename?: "CloudResourceChart";
          name: string;
          version: string;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type FetchOrgSitesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchOrgSitesQuery = {
  __typename?: "Query";
  org?: {
    __typename?: "Org";
    id: string;
    sites?: Array<{ __typename?: "Site"; id: string } | null> | null;
  } | null;
};

export type FetchSiteDevicesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchSiteDevicesQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    ancestors?: Array<{ __typename?: "Site"; id: string } | null> | null;
    devices?: {
      __typename?: "DevicesResult";
      devices?: Array<{
        __typename?: "Device";
        id: string;
        displayName?: string | null;
        tags?: Array<string> | null;
        site?: string | null;
        status?: DeviceStatus | null;
        deviceType?: string | null;
        position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
        cloudResourceChart?: {
          __typename?: "CloudResourceChart";
          name: string;
          version: string;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type MatchesViewPartsFragment = {
  __typename?: "Device";
  id: string;
  displayName?: string | null;
  tags?: Array<string> | null;
  status?: DeviceStatus | null;
  deviceType?: string | null;
  position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
  connections?: Array<{
    __typename?: "Connection";
    id: string;
    category: string;
    kind: string;
    name: string;
    source: string;
  } | null> | null;
};

export type RemoveDeviceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveDeviceMutation = { __typename?: "Mutation"; removeDevice?: string | null };

export type EditSiteMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  site: SiteInput;
}>;

export type EditSiteMutation = {
  __typename?: "Mutation";
  editSite?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    description?: string | null;
    ancestors?: Array<{
      __typename?: "Site";
      id: string;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type FetchProvisionsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchProvisionsQuery = {
  __typename?: "Query";
  provisions?: Array<{
    __typename?: "Provision";
    id: string;
    displayName?: string | null;
    status?: DeviceStatus | null;
    phases?: Array<{
      __typename?: "ProvisionPhase";
      name: string;
      progress?: { __typename?: "Progress"; step: number; goal: number } | null;
    } | null> | null;
  }> | null;
};

export type ProvisionDetailsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProvisionDetailsQuery = {
  __typename?: "Query";
  fetchProvision?: {
    __typename?: "Provision";
    lastUpdated?: any | null;
    inventory?: string | null;
    id: string;
    displayName?: string | null;
    status?: DeviceStatus | null;
    chart?: { __typename?: "ProvisionChart"; name: string; version: string } | null;
    siteTreeItem?:
      | { __typename?: "Org"; id: string; displayName?: string | null }
      | { __typename?: "Site"; id: string; displayName?: string | null }
      | null;
    phases?: Array<{
      __typename?: "ProvisionPhase";
      name: string;
      values?: string | null;
      progress?: { __typename?: "Progress"; step: number; goal: number } | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
        manifest?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FetchProvisionLogsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  endpointId: Scalars["ID"]["input"];
  hash: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
}>;

export type FetchProvisionLogsQuery = {
  __typename?: "Query";
  fetchProvision?: {
    __typename?: "Provision";
    id: string;
    logEndpoint?: {
      __typename?: "LogEndpoint";
      source?: {
        __typename?: "LogSource";
        logs?: Array<{ __typename?: "LogEntry"; log: string } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type FetchProvisionSourcesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  endpointId: Scalars["ID"]["input"];
  hash: Scalars["String"]["input"];
}>;

export type FetchProvisionSourcesQuery = {
  __typename?: "Query";
  fetchProvision?: {
    __typename?: "Provision";
    id: string;
    logEndpoint?: {
      __typename?: "LogEndpoint";
      sources?: Array<{ __typename?: "LogSource"; id: string; name: string } | null> | null;
    } | null;
  } | null;
};

export type FetchProvisionEndpointsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchProvisionEndpointsQuery = {
  __typename?: "Query";
  fetchProvision?: {
    __typename?: "Provision";
    id: string;
    logEndpoints?: Array<{
      __typename?: "LogEndpoint";
      id: string;
      hash: string;
      name: string;
    } | null> | null;
  } | null;
};

export type ProvisionFieldsFragment = {
  __typename?: "Provision";
  id: string;
  displayName?: string | null;
  status?: DeviceStatus | null;
};

export type ProfileSelectQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileSelectQuery = {
  __typename?: "Query";
  provisionCharts?: Array<{ __typename?: "ChartKey"; name: string; version: string } | null> | null;
};

export type ProvisionDeviceMutationVariables = Exact<{
  input: ProvisionInput;
}>;

export type ProvisionDeviceMutation = {
  __typename?: "Mutation";
  provision?: { __typename?: "Device"; id: string; displayName?: string | null } | null;
};

export type ProfileDetailsQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
}>;

export type ProfileDetailsQuery = {
  __typename?: "Query";
  provisionChart?: {
    __typename?: "ProvisionChart";
    name: string;
    version: string;
    phases?: Array<{
      __typename?: "ProvisionPhase";
      name: string;
      values?: string | null;
      enabled?: boolean | null;
    } | null> | null;
  } | null;
};

export type EditableProvisionQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type EditableProvisionQuery = {
  __typename?: "Query";
  fetchProvision?: {
    __typename?: "Provision";
    id: string;
    displayName?: string | null;
    inventory?: string | null;
    chart?: {
      __typename?: "ProvisionChart";
      name: string;
      version: string;
      availableVersions?: Array<string> | null;
    } | null;
    siteTreeItem?: { __typename?: "Org"; id: string } | { __typename?: "Site"; id: string } | null;
  } | null;
  devices?: {
    __typename?: "DevicesResult";
    devices?: Array<{
      __typename?: "Device";
      id: string;
      position?: { __typename?: "LatLng"; lat: number; lng: number } | null;
    }> | null;
  } | null;
};

export type UpdateProvisionMutationVariables = Exact<{
  input: UpdateProvisionInput;
}>;

export type UpdateProvisionMutation = {
  __typename?: "Mutation";
  updateProvision?: { __typename?: "Provision"; id: string } | null;
};

export type RemoveSiteMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveSiteMutation = { __typename?: "Mutation"; removeSite?: string | null };

export type ServiceChainsQueryVariables = Exact<{
  org?: InputMaybe<Scalars["ID"]["input"]>;
  site: Scalars["ID"]["input"];
}>;

export type ServiceChainsQuery = {
  __typename?: "Query";
  serviceChains?: Array<{
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    status?: Status | null;
    createdAt?: any | null;
  } | null> | null;
};

export type SiteTitleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type SiteTitleQuery = {
  __typename?: "Query";
  site?: {
    __typename?: "Site";
    id: string;
    displayName?: string | null;
    ancestors?: Array<{
      __typename?: "Site";
      id: string;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type FetchTemplateChartQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchTemplateChartQuery = {
  __typename?: "Query";
  templateChart?: {
    __typename?: "TemplateChart";
    id: string;
    chartYaml?: string | null;
    valuesYaml?: string | null;
    overridesYaml?: string | null;
    displayName?: string | null;
    vendor?: string | null;
    description?: string | null;
    logoUrl?: string | null;
    categories?: Array<string> | null;
    availableVersions?: Array<string> | null;
  } | null;
};

export type PublishBlockChartMutationVariables = Exact<{
  input: PublishBlockChartInput;
}>;

export type PublishBlockChartMutation = {
  __typename?: "Mutation";
  publishBlockChart?: {
    __typename?: "BlockChart";
    id: string;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    displayName?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
    availableVersions?: Array<string> | null;
  } | null;
};

export type TemplateChartInformationQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TemplateChartInformationQuery = {
  __typename?: "Query";
  templateChart?: {
    __typename?: "TemplateChart";
    id: string;
    displayName?: string | null;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    chartYaml?: string | null;
    valuesYaml?: string | null;
    overridesYaml?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
    availableVersions?: Array<string> | null;
  } | null;
};

export type TemplatesQueryVariables = Exact<{
  category?: InputMaybe<Scalars["String"]["input"]>;
  vendors?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
}>;

export type TemplatesQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    template?: { __typename?: "RepoSettings"; url?: string | null } | null;
  } | null;
  templateCharts?: Array<{
    __typename?: "TemplateChart";
    id: string;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    displayName?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
  } | null> | null;
};

export type DeployServiceChainMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  blocks?: InputMaybe<Array<BlockInput> | BlockInput>;
}>;

export type DeployServiceChainMutation = {
  __typename?: "Mutation";
  deployServiceChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    status?: Status | null;
    owner?: string | null;
    createdAt?: any | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      status?: Status | null;
      connections?: Array<{
        __typename?: "Connection";
        name: string;
        category: string;
        source: string;
      } | null> | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ServiceChainInstanceFragment = {
  __typename?: "ServiceChain";
  id: string;
  revision: number;
  name?: string | null;
  status?: Status | null;
  owner?: string | null;
  createdAt?: any | null;
  blocks?: Array<{
    __typename?: "Block";
    id: string;
    chainRevision: number;
    displayName?: string | null;
    status?: Status | null;
    connections?: Array<{
      __typename?: "Connection";
      name: string;
      category: string;
      source: string;
    } | null> | null;
    resources?: Array<{
      __typename?: "OktoResource";
      id: string;
      name?: string | null;
      kind?: string | null;
      status?: Status | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateServiceChainMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  blocks?: InputMaybe<Array<BlockInput> | BlockInput>;
}>;

export type UpdateServiceChainMutation = {
  __typename?: "Mutation";
  updateServiceChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    status?: Status | null;
    owner?: string | null;
    createdAt?: any | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      status?: Status | null;
      connections?: Array<{
        __typename?: "Connection";
        name: string;
        category: string;
        source: string;
      } | null> | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FetchInstanceInfoQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchInstanceInfoQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    id: string;
    name?: string | null;
    revision: number;
    createdAt?: any | null;
    history?: Array<{
      __typename?: "ServiceChain";
      id: string;
      createdAt?: any | null;
      revision: number;
    }> | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      status?: Status | null;
      values?: string | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        name: string;
        kind: string;
        category: string;
        source: string;
      } | null> | null;
      chart?: {
        __typename?: "BlockChart";
        id: string;
        name: string;
        description?: string | null;
        logoUrl?: string | null;
        vendor?: string | null;
        version: string;
      } | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
        manifest?: string | null;
      } | null> | null;
      controls?: Array<{
        __typename?: "BlockControl";
        id: string;
        displayName?: string | null;
        value?: string | null;
        options?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FetchRevisionQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  revision?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FetchRevisionQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    id: string;
    name?: string | null;
    revision: number;
    history?: Array<{
      __typename?: "ServiceChain";
      id: string;
      createdAt?: any | null;
      revision: number;
    }> | null;
    currentRevision?: Array<{
      __typename?: "ServiceChain";
      id: string;
      revision: number;
      blocks?: Array<{
        __typename?: "Block";
        id: string;
        chainRevision: number;
        displayName?: string | null;
        status?: Status | null;
        values?: string | null;
        connections?: Array<{
          __typename?: "Connection";
          id: string;
          name: string;
          kind: string;
          category: string;
          source: string;
        } | null> | null;
        chart?: {
          __typename?: "BlockChart";
          id: string;
          name: string;
          description?: string | null;
          logoUrl?: string | null;
          vendor?: string | null;
          version: string;
        } | null;
        resources?: Array<{
          __typename?: "OktoResource";
          id: string;
          name?: string | null;
          kind?: string | null;
          status?: Status | null;
          manifest?: string | null;
        } | null> | null;
        controls?: Array<{
          __typename?: "BlockControl";
          id: string;
          displayName?: string | null;
          value?: string | null;
          options?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type BlockInfoFragment = {
  __typename?: "Block";
  id: string;
  chainRevision: number;
  displayName?: string | null;
  status?: Status | null;
  values?: string | null;
  connections?: Array<{
    __typename?: "Connection";
    id: string;
    name: string;
    kind: string;
    category: string;
    source: string;
  } | null> | null;
  chart?: {
    __typename?: "BlockChart";
    id: string;
    name: string;
    description?: string | null;
    logoUrl?: string | null;
    vendor?: string | null;
    version: string;
  } | null;
  resources?: Array<{
    __typename?: "OktoResource";
    id: string;
    name?: string | null;
    kind?: string | null;
    status?: Status | null;
    manifest?: string | null;
  } | null> | null;
  controls?: Array<{
    __typename?: "BlockControl";
    id: string;
    displayName?: string | null;
    value?: string | null;
    options?: Array<string | null> | null;
  } | null> | null;
};

export type FetchEndpointSourcesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  endpointId: Scalars["ID"]["input"];
  hash: Scalars["String"]["input"];
}>;

export type FetchEndpointSourcesQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      logEndpoint?: {
        __typename?: "LogEndpoint";
        sources?: Array<{ __typename?: "LogSource"; id: string; name: string } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type FetchInstanceEndpointsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchInstanceEndpointsQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      logEndpoints?: Array<{
        __typename?: "LogEndpoint";
        id: string;
        hash: string;
        name: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FetchSourceLogsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  endpointId: Scalars["ID"]["input"];
  hash: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
  logFilterSimpleQuery?: InputMaybe<Scalars["String"]["input"]>;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
}>;

export type FetchSourceLogsQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      logEndpoint?: {
        __typename?: "LogEndpoint";
        source?: {
          __typename?: "LogSource";
          logs?: Array<{
            __typename?: "LogEntry";
            log: string;
            metadata?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateControlMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
}>;

export type UpdateControlMutation = {
  __typename?: "Mutation";
  setControlValue?: {
    __typename?: "BlockControl";
    id: string;
    value?: string | null;
    displayName?: string | null;
    options?: Array<string | null> | null;
  } | null;
};

export type OktoResourceLogsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OktoResourceLogsQuery = { __typename?: "Query"; oktoResourceLogs: string };

export type RemoveServiceChainMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveServiceChainMutation = {
  __typename?: "Mutation";
  removeServiceChain?: string | null;
};

export type RollbackRevisionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  revision: Scalars["Int"]["input"];
}>;

export type RollbackRevisionMutation = {
  __typename?: "Mutation";
  rollbackServiceChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    createdAt?: any | null;
    history?: Array<{ __typename?: "ServiceChain"; id: string; revision: number }> | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      status?: Status | null;
      values?: string | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        name: string;
        kind: string;
        category: string;
        source: string;
      } | null> | null;
      chart?: {
        __typename?: "BlockChart";
        id: string;
        name: string;
        description?: string | null;
        logoUrl?: string | null;
        vendor?: string | null;
        version: string;
      } | null;
      resources?: Array<{
        __typename?: "OktoResource";
        id: string;
        name?: string | null;
        kind?: string | null;
        status?: Status | null;
        manifest?: string | null;
      } | null> | null;
      controls?: Array<{
        __typename?: "BlockControl";
        id: string;
        displayName?: string | null;
        value?: string | null;
        options?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ServiceChainStatusFragment = {
  __typename?: "ServiceChain";
  id: string;
  status?: Status | null;
};

export type AuditLogNodeFragment = {
  __typename?: "AuditLogEntry";
  time?: any | null;
  message?: string | null;
  type?: AuditLogEventType | null;
  reason?: string | null;
  entityType?: AuditLogEntityType | null;
  org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
  user?: { __typename?: "User"; id: string; email?: string | null } | null;
  entity?:
    | { __typename?: "BlockChart"; id: string; blockChartName: string }
    | { __typename?: "Device"; id: string; name?: string | null }
    | { __typename?: "FederationGuest"; id: string; name?: string | null }
    | { __typename?: "FederationHost"; id: string; name?: string | null }
    | { __typename?: "GenericAuditLogEntity"; name?: string | null; id: string }
    | { __typename?: "Org"; id: string; name?: string | null }
    | { __typename?: "Provision"; status?: DeviceStatus | null; id: string; name?: string | null }
    | { __typename?: "ServiceChain"; name?: string | null; revision: number; id: string }
    | { __typename?: "Site"; id: string; name?: string | null }
    | { __typename?: "User"; id: string; name?: string | null }
    | null;
};

export type FetchAuditLogsQueryVariables = Exact<{
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  pageSize: Scalars["Int"]["input"];
  beforeCursor?: InputMaybe<Scalars["String"]["input"]>;
  afterCursor?: InputMaybe<Scalars["String"]["input"]>;
  eventTypes?: InputMaybe<Array<AuditLogEventType> | AuditLogEventType>;
}>;

export type FetchAuditLogsQuery = {
  __typename?: "Query";
  auditLogs?: {
    __typename?: "AuditLogs";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "AuditLogEntryEdge";
      cursor: string;
      node?: {
        __typename?: "AuditLogEntry";
        time?: any | null;
        message?: string | null;
        type?: AuditLogEventType | null;
        reason?: string | null;
        entityType?: AuditLogEntityType | null;
        org?: { __typename?: "Org"; id: string; displayName?: string | null } | null;
        user?: { __typename?: "User"; id: string; email?: string | null } | null;
        entity?:
          | { __typename?: "BlockChart"; id: string; blockChartName: string }
          | { __typename?: "Device"; id: string; name?: string | null }
          | { __typename?: "FederationGuest"; id: string; name?: string | null }
          | { __typename?: "FederationHost"; id: string; name?: string | null }
          | { __typename?: "GenericAuditLogEntity"; name?: string | null; id: string }
          | { __typename?: "Org"; id: string; name?: string | null }
          | {
              __typename?: "Provision";
              status?: DeviceStatus | null;
              id: string;
              name?: string | null;
            }
          | { __typename?: "ServiceChain"; name?: string | null; revision: number; id: string }
          | { __typename?: "Site"; id: string; name?: string | null }
          | { __typename?: "User"; id: string; name?: string | null }
          | null;
      } | null;
    } | null>;
    pageInfo?: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    } | null;
  } | null;
};

export type FetchEventLogsQueryVariables = Exact<{
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  pageSize: Scalars["Int"]["input"];
  beforeCursor?: InputMaybe<Scalars["String"]["input"]>;
  afterCursor?: InputMaybe<Scalars["String"]["input"]>;
  eventTypes?: InputMaybe<Array<EventLogType> | EventLogType>;
}>;

export type FetchEventLogsQuery = {
  __typename?: "Query";
  eventLogs?: {
    __typename?: "EventLogs";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "EventLogEntryEdge";
      cursor: string;
      node?: {
        __typename?: "EventLogEntry";
        time?: any | null;
        message?: string | null;
        type?: EventLogType | null;
        reason?: string | null;
        resource?: {
          __typename?: "OktoResource";
          id: string;
          name?: string | null;
          kind?: string | null;
          status?: Status | null;
          namespace?: string | null;
          manifest?: string | null;
        } | null;
      } | null;
    } | null>;
    pageInfo?: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    } | null;
  } | null;
};

export type FetchBlockChartQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchBlockChartQuery = {
  __typename?: "Query";
  blockChart?: {
    __typename?: "BlockChart";
    id: string;
    chartYaml?: string | null;
    overridesYaml?: string | null;
  } | null;
};

export type FetchBlockInformationQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchBlockInformationQuery = {
  __typename?: "Query";
  blockChart?: {
    __typename?: "BlockChart";
    id: string;
    displayName?: string | null;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    chartYaml?: string | null;
    overridesYaml?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
    availableVersions?: Array<string> | null;
  } | null;
};

export type PopulateMarketplaceQueryVariables = Exact<{
  category?: InputMaybe<Scalars["String"]["input"]>;
  vendors?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
  chartType?: InputMaybe<BlockChartType>;
}>;

export type PopulateMarketplaceQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    block?: { __typename?: "RepoSettings"; url?: string | null } | null;
  } | null;
  blockCharts?: Array<{
    __typename?: "BlockChart";
    id: string;
    name: string;
    version: string;
    categories?: Array<string> | null;
    description?: string | null;
    displayName?: string | null;
    vendor?: string | null;
    logoUrl?: string | null;
    availableVersions?: Array<string> | null;
  } | null> | null;
};

export type ServiceDesignerFormParamsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ServiceDesignerFormParamsQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    id: string;
    revision: number;
    name?: string | null;
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      chainRevision: number;
      displayName?: string | null;
      values?: string | null;
      selectedVersion?: string | null;
      chart?: {
        __typename?: "BlockChart";
        id: string;
        version: string;
        name: string;
        availableVersions?: Array<string> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ServiceDesignerPreviousValuesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ServiceDesignerPreviousValuesQuery = {
  __typename?: "Query";
  serviceChain?: {
    __typename?: "ServiceChain";
    blocks?: Array<{
      __typename?: "Block";
      id: string;
      displayName?: string | null;
      values?: string | null;
    } | null> | null;
  } | null;
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AuditLogEntity: [
      "BlockChart",
      "Device",
      "FederationGuest",
      "FederationHost",
      "GenericAuditLogEntity",
      "Org",
      "Provision",
      "ServiceChain",
      "Site",
      "User"
    ],
    SiteTreeItem: ["Org", "Site"]
  }
};
export default result;
