import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import { MenuButton } from "@src/Header";
import { useToggle } from "@src/Hooks/toggle";
import { supportedLngs } from "@src/i18n";

import { HeaderClickable, HeaderItem, HeaderLabel, UserMenu } from "./HeaderStyles";

const DropdownMenu = styled(UserMenu)`
  row-gap: 0.5em;
`;

export function SelectLanguageMenu() {
  const { i18n } = useTranslation();
  const {
    state: isOpenDashboards,
    toggle: toggleMenuDashboards,
    off: closeMenuDashboards
  } = useToggle();

  return (
    <HeaderItem>
      <HeaderClickable onClick={toggleMenuDashboards} title="switch language">
        <HeaderLabel>{i18n.language}</HeaderLabel>
        {isOpenDashboards ? (
          <UpArrow height={14} width={14} />
        ) : (
          <DownArrow height={14} width={14} />
        )}
      </HeaderClickable>
      {isOpenDashboards && (
        <DropdownMenu onClick={closeMenuDashboards}>
          {supportedLngs
            .filter(lang => i18n.resolvedLanguage !== lang)
            .map(lang => (
              <MenuButton key={lang} onClick={() => changeLanguage(lang)}>
                {lang}
              </MenuButton>
            ))}
        </DropdownMenu>
      )}
    </HeaderItem>
  );
}
