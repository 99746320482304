import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const isProduction = process.env.NODE_ENV === "production";

export const supportedLngs = ["en", "es"];

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: !isProduction,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    supportedLngs
  });

export default i18n;
